import './Merch.css';

function Merch() {
    return (
        <div className="App">
            <div >
                <div className="site-banner-header">
                    <h1 >
                        Merchandise
                    </h1>
                </div>
                <div class="row">
         {// eslint-disable-next-line
}<iframe src="https://bubbacoltrane.bandcamp.com/merch" width="100%" height="450" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
      </div>
                  
                </div>
            </div>
        );
      }
      
      export default Merch;
      
